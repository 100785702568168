@import '@/styles/_mixins'

.post
  color: #484848
  margin: 0
  padding: 60px 0 120px

  img
    height: auto
    max-width: 100%

  p
    font-size: min(14px, 100%)!important

.imageContainer
  height: 60vh
  margin: 40px auto
  object-fit: contain
  position: relative
  width: 80%

  @include breakpoints('sm')
    height: 70vh
  @include breakpoints('md')
    height: 55vh
  @include breakpoints('lg')
    height: 60vh
