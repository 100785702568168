.container
  align-items: center
  display: flex
  flex-direction: column
  height: 100vh
  justify-content: center
  left: 0
  position: fixed
  top: 0
  width: 100vw

.large
  // img
  //   height: 40px
  //   width: 40px
